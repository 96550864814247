import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Tabs, Tab, Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import ContentGroup from './ContentGroup'

const TabsWrapper = styled('div')(({ theme, displayDesktop, displayMobile }) => ({
  display: displayDesktop !== false ? 'block' : 'none',
  [theme.breakpoints.down('sm')]: {
    display: displayMobile !== false ? 'block' : 'none',
  },
}))

const TabStyles = styled(Tabs)(({ theme, multipleTabs }) => ({
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  [theme.breakpoints.down('sm')]: {
    marginRight: multipleTabs ? '-15px' : null,
  },
  '& [role="tablist"]': {
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: multipleTabs ? 'flex-start' : 'space-around',
    },
  },
  '& .MuiTabScrollButton-root': {
    display: 'none',
  },
}))

const TabPanelStyles = styled('div')({
  margin: '20px 0',
  '& .collection-buttons a': {
    padding: '5px 10px',
  },
})

const TabPanel = ({ children, value, index, className }) => (
  <TabPanelStyles
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    className={className}
  >
    {value === index && (
      <Box sx={{ p: 0 }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </TabPanelStyles>
)

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
}

const StrapiTabs = ({ data }) => {
  const tabValueRef = useRef(null)
  const TabsData = data?.TabGroup?.Tabs
  const maxTabs = TabsData?.length
  const multipleTabs = TabsData?.length > 3

  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search)
    const tabParam = Number(params.get('tab')) - 1
    if (tabValueRef.current !== tabParam) {
      tabValueRef.current = tabParam
    }
  }

  const [value, setValue] = useState(
    tabValueRef.current < maxTabs && tabValueRef.current >= 0 ? tabValueRef.current : 0,
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const tabValue = tabValueRef.current
    const tabExists = tabValue < maxTabs && tabValue >= 0
    if (typeof tabValue === 'number' && tabExists) {
      setValue(tabValue)
    }

    /* eslint-disable-next-line */
  }, [maxTabs, tabValueRef.current])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <TabsWrapper
      data-testid={data?.Tabs?.testId}
      displayDesktop={data?.TabGroup?.DisplayDesktop}
      displayMobile={data?.TabGroup?.DisplayMobile}
    >
      <Box>
        <TabStyles
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          multipleTabs={multipleTabs}
        >
          {TabsData && TabsData.map(tab => <Tab key={`tab-${tab.TabName}`} label={tab.TabName} />)}
        </TabStyles>
      </Box>
      {TabsData &&
        TabsData.map((tab, index) => (
          <TabPanel key={`tab-panel-${tab.TabName}`} value={value} index={index}>
            <ContentGroup data={tab?.ContentGroup} contentTab={tab.TabName !== null} />
          </TabPanel>
        ))}
    </TabsWrapper>
  )
}

export default StrapiTabs

StrapiTabs.propTypes = {
  data: PropTypes.object,
}

export const tabGroupFragment = graphql`
  fragment StrapiPageTabGroupFragment on StrapiTabGroup {
    id
    Title
    testId
    Tabs {
      TabName
      ContentGroup {
        ...StrapiContentGroupFragment
      }
    }
    DisplayDesktop
    DisplayMobile
  }
`
